import PropTypes from 'prop-types';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import Newsletter from 'components/content-components/Newsletter';
import OverlayHeader from 'components/overlays/OverlayHeader';
import Heading from 'components/text/Heading';

const Wrapper = styled('div', {
    width: ['100%', null, null, null, null, '420px'],
    height: '100%',
    padding: '4px',
});

const InnerWraper = styled('div', {
    alignItems: 'space-between',
    background: 'var(--color-contrast-lowest)',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginRight: [0, null, null, null, null, '4px'],
    padding: '8px',
    position: 'relative',
    width: '100%',
});

const NotifyMeOverlay = ({
    notifyMeHeading = '',
    notifyMeHeadingType = '',
    notifyMeLabel = '',
    notifyMeNewsletter = {},
    notifyMeParagraph = '',
    // variationID = '',
}) => {
    const [css] = useStyletron();

    // TODO: Get and show product.
    // TODO: Make newsletter signup work.
    // TODO: Add correct "Few left" data.

    const headingSettings = transformHeading({
        type: notifyMeHeadingType,
        defaultType: 'span',
    });

    return (
        <Wrapper>
            <InnerWraper>
                <OverlayHeader headingText={notifyMeLabel} overlayId="notifyme" />
                <ScrollableBox $style={{ flexGrow: 1 }}>
                    <div className={css({ padding: '0 4px' })}>
                        <Heading $style={{ marignTop: '24px' }} as={headingSettings.type} fontKeys="Primary/20-120_-1">
                            {notifyMeHeading}
                        </Heading>
                        <div className={css({ marginTop: '8px' })}>
                            <RichText data={notifyMeParagraph} />
                        </div>
                        <div className={css({ marginTop: '36px' })}>
                            <Newsletter
                                buttonExtraText="Few left"
                                buttonIcon="bell"
                                buttonStyling={{ width: '100%', marginTop: '36px' }}
                                {...notifyMeNewsletter}
                            />
                        </div>
                    </div>
                </ScrollableBox>
            </InnerWraper>
        </Wrapper>
    );
};

NotifyMeOverlay.propTypes = {
    notifyMeHeading: PropTypes.string,
    notifyMeHeadingType: PropTypes.string,
    notifyMeLabel: PropTypes.string,
    notifyMeNewsletter: PropTypes.object,
    notifyMeParagraph: PropTypes.string,
    variationID: PropTypes.string,
};

export default NotifyMeOverlay;
